/* Landing Page */
.landing-page {
    .header {
        background-color: transparent;
        .searchBar {
            display: none;
        }
    }
    .hero-landing {
        width: 100%;
        height: calc(100vh - 104px);
        background-color: var(--osc), 10%;
        background: linear-gradient(
            180.29deg,
            rgba(5, 17, 28, 0.9) 28.46%,
            rgba(67, 98, 126, 0) 117.13%
        );
        display: flex;
        flex-direction: column;
        justify-content: center;
        #background-video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            object-fit: cover;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
        .hero-claim {
            h2,
            h3 {
                margin: 0 auto;
                font-family: Ubuntu;
                text-align: center;
            }
            h2 {
                width: 90%;
                font-size: 2rem;
            }
            h3 {
                display: none;
            }
            .hero-login {
                margin: 30px 0 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p {
                    margin: 0 1%;
                    // margin: 10px auto;
                    text-align: center;
                    font-size: 1.5rem;
                    a {
                        text-decoration: none;
                        margin: 10px 5px;
                    }
                }
                .primary-button {
                    background-color: var(--auf);
                    border: 1px solid var(--auf);
                    text-transform: uppercase;
                    padding: 3px 5px;
                    border-radius: 2px;
                    text-decoration: none;
                }
                .secondary-button {
                    display: inline-block;
                    padding: 3px 5px;
                    border-radius: 2px;
                    text-decoration: none;
                    border: 2px solid var(--auf);
                    text-transform: uppercase;
                }
            }
        }
    }

    /* Landing Page Tablet */
    @media only screen and (min-width: 481px) {
        .hero-landing {
            height: calc(100vh - 84px);
            .hero-claim {
                h2 {
                    width: 50%;
                    max-width: 440px;
                    min-width: 430px;
                    font-size: 2.5rem;
                }
                h3 {
                    width: 70%;
                    min-width: 430px;
                    max-width: 564px;
                    display: block;
                    font-size: 2rem;
                    font-weight: 200;
                }
                .hero-login {
                    margin: 30px 0 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    p {
                        margin: 0 1%;
                        // margin: 10px auto;
                        text-align: center;
                        font-size: 1.5rem;
                        a {
                            text-decoration: none;
                            margin: 10px 5px;
                        }
                    }
                    .primary-button {
                        background-color: var(--auf);
                        border: 1px solid var(--auf);
                        text-transform: uppercase;
                        padding: 3px 5px;
                        border-radius: 2px;
                        text-decoration: none;
                    }
                    .secondary-button {
                        display: inline-block;
                        padding: 3px 5px;
                        border-radius: 2px;
                        text-decoration: none;
                        border: 2px solid var(--auf);
                        text-transform: uppercase;
                    }
                }
            }

            // a {
            //   cursor: pointer;
            //   :hover {
            //     color: var(--neu-ter);
            //   }
            // }
        }
    }

    /* Landing Page Fullscreen */
    @media only screen and (min-width: 769px) {
        .hero-landing {
            .hero-claim {
                h2 {
                    font-size: 3rem;
                }
                h3 {
                    font-size: 2.2rem;
                }
            }
        }
    }
}
