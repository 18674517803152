@media screen and (min-width: 481px) {
  .browse {
      .header {
          position: fixed;
          background-color: transparent;
      }
      main {
          // Banner venta de partidos
          .ppv-banner {
              padding-top: 55px;
          }
          .destacado { 
            height: 65vw;
            .destacado-bottom-gradient {
                .destacadoInfo {
                    .primary-button {
                        font-size: 1.5rem;
                    }
                }
            }
          }
          .biblioteca {
              .swiper-row {
                  h2 {
                      font-size: 1.5rem;
                  }
              }
          }
      }
  }
}