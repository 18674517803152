// Payment page
.payment-page {
    .header {
        background: rgb(var(--osc));
        .rightNav {
            .searchIcon {
                display: none;
            }
        }
    }
    .card-loader.hide {
        display: none;
    }
    .mercado-pago-form {
        .custom-error {
            color: #b41c32;
            padding: 10px;
            background: #ffeeee;
            margin: 5px;
            border-radius: 5px;
            border: solid 1px #ffd7e0;
            text-align: center;
            font-size: 13px;
        }
        max-width: 400px;
        margin: 20px auto;
        .form-header {
            width: 100%;
            margin: 70px auto 25px;
            background-color: var(--color-claro);
            text-align: center;
            h1,
            h2 {
                font-family: system-ui;
                color: var(--auf);
            }
            h2 {
                font-size: 20px;
                color: rgb(16, 104, 193);
            }
        }
        .form-content {
            .rccs {
                .rccs__card--mastercard .rccs__number,
                .rccs__card--mastercard .rccs__name,
                .rccs__card--mastercard .rccs__expiry .rccs__expiry__valid,
                .rccs__card--mastercard .rccs__expiry .rccs__expiry__value,
                .rccs__card--mastercard .rccs__cvc {
                    color: #555;
                }
                .rccs__card--maestro .rccs__number,
                .rccs__card--maestro .rccs__name,
                .rccs__card--maestro .rccs__expiry .rccs__expiry__valid,
                .rccs__card--maestro .rccs__expiry .rccs__expiry__value,
                .rccs__card--maestro .rccs__cvc {
                    color: #555;
                }
            }
        }
        .form-checkout {
            margin: 0 10px;
            display: flex;
            flex-direction: column;
            h3 {
                margin: 10px 0 0;
                padding: 10px 0 0;
                font-size: 15px;
                color: var(--auf);
                border-top: solid 1px #d0ecff;
            }
            h4 {
                margin: 10px 0 -5px 0;
                font-size: 15px;
                color: rgba(var(--osc), 75%);
            }
            .form-control {
                input {
                    width: calc(100% - 10px);
                    height: 35px;
                    padding: 0 0 0 10px;
                    color: rgb(var(--osc));
                    border: 1px solid var(--auf);
                    border-radius: 2px;
                    margin: 10px 0 0;
                    font-weight: 300;
                    // &.card-holder,
                    // &.card-number,
                    // &.card-user-email,
                    // &.card-user-document-number {
                    //   // width: 90%;
                    // }
                }
                select {
                    width: 30%;
                    height: 37px;
                    padding: 0 0 0 10px;
                    color: rgb(var(--osc));
                    border: 1px solid var(--auf);
                    border-radius: 2px;
                    margin: 10px 0 0;
                    font-size: 16px;
                    font-weight: 300;
                }
                ::placeholder {
                    font-size: 15px;
                    font-weight: 400;
                    color: var(--placeholders);
                    // color: red;
                }
                &.small-inputs {
                    // width: calc(90% + 12px);
                    display: flex;
                    .expiration {
                        width: 50%;
                        display: flex;
                        input {
                            width: 25%;
                            &.card-exp-month {
                                margin-right: 10px;
                            }
                        }
                    }
                    .cvc {
                        text-align: right;
                        .card-cvc {
                            width: 50%;
                            margin: 10px 0 0 auto;
                        }
                        p {
                            color: #303030;
                            font-weight: 300;
                        }
                    }
                }
                button {
                    width: 100%;
                    display: block;
                    height: 50px;
                    font-size: 20px;
                    margin: 10px 0 0;
                    background-color: var(--auf);
                    color: var(--color-claro);
                    border: none;
                    border-radius: 3px;
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out 0.5s;
                    cursor: pointer;
                }
                .cannot-submit {
                    opacity: 0.4;
                    cursor: not-allowed;
                }
                .progress-bar {
                    width: 100%;
                    padding: 5px;
                }
            }
            .card-details-titles {
                // width: calc(90% + 12px);
                display: flex;
                justify-content: space-between;
            }
            .card-document-info {
                // width: calc(90% + 12px);
                display: flex;
                .card-user-document-number {
                    margin: 10px 0 0 10px;
                }
            }
            .payment-info {
                height: 1px;
                visibility: hidden;
            }
        }
    }
} 

.pagar-en-efectivo {
    a {
        color: var(--auf); 
        font-size: 16px;
        text-decoration: none; 
    }
}
