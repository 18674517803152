@media screen and (min-width: 769px) {
    .browse {
        .header {
            background: linear-gradient(
                180deg,
                rgba(20, 20, 20, 0.5) 0%,
                rgba(20, 20, 20, 0.3) 25%,
                rgba(20, 20, 20, 0) 75%
            );
        }
        .header-active {
            background-color: rgba(var(--osc));
        }
        main {
            // Banner venta de partidos
            .ppv-banner {
                p {
                    font-size: 20px;
                }
                span {
                    font-size: 15px;
                }
                .ppv-banner-button {
                    width: 50%;
                    margin: 10px auto;
                    font-size: 15px;
                }
            }
            .destacado {
                margin: 0;
                height: 85vh;
                position: relative;
                .destacado-bottom-gradient {
                    position: unset;
                    img {
                        width: auto;
                        max-width: 90vw;
                        height: 100%;
                        max-height: 50.65vw;
                        position: absolute;
                        right: 0;
                    }
                    .destacadoInfo {
                        // background: linear-gradient(90deg, rgba(5,22,38,1) 0%, rgba(5,22,38,1) 45%, rgba(5,22,38,0) 100%);
                        background: linear-gradient(
                            90deg,
                            #051626 0%,
                            #051626 calc(100vw - 150vh),
                            rgba(5, 22, 38, 0) 100%
                        );
                    }
                }
            }
            .biblioteca {
                top: -10vh;
                .nuevos.nuevos-en-vivo {
                    margin: 150px 0 0;
                }
                .nuevos.destacados {
                    margin-top: 0;
                }
                .nuevos.nuevos-en-vivo {
                    margin: 0px 0 0px;
                }
                .categories {
                    .swiper-row {
                        .swiper-container {
                            .swiper-wrapper {
                                .swiper-slide {
                                    &:hover {
                                        transition: none;
                                        z-index: 1;
                                        padding: 2px;
                                        border: 3px solid rgb(208, 218, 227);
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }
                }
                .swiper-row,
                .swiper-row-big,
                .swiper-row-16-9 {
                    h2 {
                        margin: 0;
                        position: relative;
                        font-size: 2rem;
                        a {
                            // position: absolute;
                            z-index: 1;
                        }
                    }
                    .swiper-container {
                        padding: 10px 4% 25px;
                        &:hover .swiper-button-prev::after {
                            display: block;
                        }
                        &:hover .swiper-button-next::after {
                            display: block;
                        }
                        .swiper-button-prev,
                        .swiper-button-next {
                            &::after {
                                display: none;
                                font-size: 2.4vw !important;
                            }
                            &:hover::after {
                                transform: scale(1.25);
                            }
                        }
                        .swiper-wrapper {
                            .swiper-slide {
                                box-sizing: border-box;
                                transition: 500ms all;
                                z-index: 0;
                                transform-origin: center center;
                                &:hover {
                                    transform: scale(1.1);
                                    transition: all 200ms ease-in-out;
                                    transition-delay: 200ms;
                                    z-index: 1;
                                    padding: 2px;
                                    border: 3px solid rgb(208, 218, 227);
                                    border-radius: 5px;
                                }
                                &:hover .thumbtile-info {
                                    // transition: transform 250ms;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    margin-left: auto;
                                    margin-right: auto;
                                    background: var(--dark-color);
                                    width: 99%;
                                    position: absolute;
                                    border: none;
                                    // font-size: 11px;
                                    border-radius: 2px;
                                    opacity: 0.9;
                                    display: block;
                                    animation: bounce 0.4s;
                                    box-shadow: rgba(255, 255, 255, 0.2) 0px 7px
                                        29px 0px;

                                    // animation-delay: 3s;
                                    .thumbtile-info--links {
                                        display: flex;
                                        justify-content: space-around;
                                        .ver,
                                        .masInfo {
                                            width: 45%;
                                            margin-top: 5px;
                                            text-align: center;
                                            text-decoration: none;
                                            text-transform: uppercase;
                                            font-size: 1rem;
                                            border-radius: 2px;
                                        }
                                        .ver {
                                            padding: 2px 0 0;
                                            color: var(--pri);
                                            background-color: var(--pri);
                                            color: var(--color-claro);
                                        }
                                        .masInfo {
                                            background-color: transparent;
                                            border: 1px solid;
                                            cursor: pointer;
                                        }
                                    }
                                    .thumbtile-info--title {
                                        padding: 5px;
                                        font-size: 1rem; 
                                        line-height: 2em;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
