.footer {
    background-color: var(--dark-color);
    color: var(--footer-text);
    .footer-top {
        color: var(--footer-text);
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        .footer-left {
            .mobile-apps {
                p {
                    color: var(--footer-text);
                    margin: 0 0 5px;
                }
                .mobile-apps-links {
                    display: flex;
                    justify-content: space-between;
                    img {
                        width: 90%;
                        max-width: 120px;
                    }
                }
            }
        }
        .footer-right {
            span {
                color: var(--footer-text);
                margin: 0 5px 5px 0;
                display: block;
                text-align: right;
                font-size: 1rem;
            }
            .social {
                display: flex;
                justify-content: space-around;
                align-items: center;
                img {
                    width: 25px;
                    margin: 0 2px;
                    color: var(--footer-text);
                }
            }
        }
    }
    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        img {
            width: 64px;
            height: 15px;
            padding-left: 15px;
        }
        .footer-text {
            color: var(--footer-text);
            text-align: right;
            padding-right: 10px;
            p {
                color: var(--footer-text);
                a {
                    color: var(--footer-text);
                    text-decoration: none;
                }
            }
            .powered {
                a {
                    color: var(--auf);
                }
            }
        }
    }
}

@media screen and (min-width: 481px) {
    .footer {
        .footer-top {
            padding: 10px 10px 0;
            .footer-left {
                .mobile-apps {
                    display: flex;
                    align-items: center;
                    p {
                        width: 150px;
                        margin-right: 2%;
                        text-align: right;
                    }
                }
            }
        }
        .footer-bottom {
            padding: 0;
            img {
                width: 85px;
                height: 20px;
                padding-left: 20px;
                margin-top: -20px;
            }
            .footer-text {
                min-width: 300px;
                display: flex;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                line-height: 28px;
                p {
                    margin: 0 0 0 2%;
                }
            }
        }
    }
}
