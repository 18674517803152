.payment-page {
  main {
    .checkout-container {
      width: 100%; 
      margin: 40px 0 0; 
      .product-select {
        .products-container {
          .products {
            .form-header { 
              h1 {
                padding-top: 10px;
                text-align: center;
                color: var(--auf); 
              }
            }
            .products-list {
              .product-elements {
                padding: 10px;
                margin: 5px;
                cursor: pointer;
                .product-name { 
                  font-size: 18px;
                }
                .product-price {
                  font-size: 18px;
                }
              }
            }
          }
        }
      } 
      .card-info {
        .mercado-pago-form {
          .form-header {
            margin: 0; 
          }
        }
      }
    }
  }
} 

@media screen and (max-width: 480px) {
  .payment-page {
    main {
      .checkout-container {
        .card-info {
          .mercado-pago-form { 
            .form-content {
              .rccs {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 481px) {
  .payment-page {
    main {
      .checkout-container {
        display: flex; 
        justify-content: center;
        margin-top: 55px; 
        .card-info { 
          .mercado-pago-form { 
            margin: 0;
            .form-header {
              margin: 0; 
              h1 {
                padding-top: 15px; 
                font-size: 2em; 
              } 
              h2 {
                padding-bottom: 15px; 
                font-size: 2em; 
              }
            }
          }
        }
      }
    }
  }

}

