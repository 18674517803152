.payment-page {
  main {
    .checkout-opm-container {
      display: flex; 
      flex-direction: column;
      .product-select { 
        .checkout { 
          min-height: 0; 
          background-color: #fff; 
          .checkout-contents {
            .form-header { 
              margin: 0 auto;
              background-color: #fff;
              h1 {
                color: var(--auf);
                font-size: 2rem;
              }
            }
          }
        }
      }
      .opm-details {
        .form-header {
          margin: 15px auto;

        }
      }
    }
  }
} 

@media screen and (min-width: 481px) {
  .payment-page {
    main { 
      .checkout-opm-container {
        flex-direction: row; 
        justify-content: center; 
        .product-select {
          .checkout { 
            margin-top: 55px;
          }
        }
        .opm-details {
          margin-top: 55px;
        }
      }
    }
  }
}